<template>
  <div class="form-container">
    <q-form @submit="onSubmit" class="template-name">
      <q-input
        counter
        :maxlength="maxLengthName"
        square
        outlined
        bottom-slots
        v-model="name"
        placeholder="Entrez un nom de modèle"
        type="text"
        @input.native="e => checkName(e)"
        lazy-rules
        :readonly="readOnly"
        class="name"
        :rules="[
          val => (val && val.length > 0) || 'Please type your template name'
        ]"
      >
        <template v-slot:hint>
          Nom du modèle
        </template>
      </q-input>

      <div class="actions-button">
        <q-btn
          v-if="isEdit && canDelete"
          label="Supprimer"
          class="btn-cancel"
          type="button"
          @click="remove"
        >
          <q-icon class="trash-red-icon" />
        </q-btn>
        <q-btn
          :loading="loading"
          v-if="isDownload"
          class="btn-white"
          label="Download"
          @click="download"
        >
          <q-icon class="download-icon" />
        </q-btn>
        <q-btn
          type="submit"
          label="Sauvegarder"
          class="btn-save"
          :loading="isSubmit"
          v-if="!readOnly"
        >
          <q-icon class="save-icon" />
        </q-btn>
      </div>
    </q-form>
    <DeleteModal
      :content.sync="contentDeleteModal"
      :is-show.sync="isDelete"
      @closeModal="closeDeleteModal"
      @removed="removed"
    />
    <a href="" download ref="downloadLink"></a>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DeleteModal from '@/components/modals/Delete'
export default {
  name: 'TemplateName',
  components: { DeleteModal },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    isDownload: {
      type: Boolean,
      default: false
    },
    isReport: {
      type: Boolean,
      default: false
    },
    isSubmit: {
      type: Boolean,
      default: false
    },
    getName: {
      type: Boolean,
      default: false
    },
    file: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      reportId: Object.keys(this.file).length ? this.file.reportId : '',
      name: this.file.name,
      defaultName: this.file.name,
      modelId: Object.keys(this.file).length ? this.file.modelId : null,
      maxLengthName: 100,
      isDelete: false,
      contentDelete: {
        title: 'Supprimer le modèle',
        text: [
          'Êtes-vous sûr de vouloir supprimer ce modèle ?'
        ]
      },
      contentDeleteReport: {
        title: 'Delete report',
        text: [
          'Are you sure want to delete this report?',
          'You can’t undo this action.'
        ]
      },
      canDelete: false,
      readOnly: false
    }
  },
  computed: {
    ...mapGetters('documents', [
      'getErrorRemovingModel',
      'getErrorDownload',
      'getErrorRemovingReport'
    ]),
    ...mapGetters('users', ['getCurrentUser']),
    contentDeleteModal() {
      return this.reportId ? this.contentDeleteReport : this.contentDelete
    }
  },
  mounted() {
    const { name } = this.$route
    const userRights = this.getCurrentUser?.userRights

    if (name === 'EditTemplate' && userRights && userRights.rightDeleteModel) {
      this.canDelete = true
    }

    if (
      name === 'EditDocument' &&
      userRights &&
      userRights.rightDeleteDocument
    ) {
      this.canDelete = true
    }

    this.readOnly = userRights && !userRights.rightModifyModel
  },
  methods: {
    ...mapActions('documents', [
      'removeModel',
      'downloadByReportId',
      'removeReport'
    ]),
    onSubmit(e) {
      this.$emit('getFileName', this.name)
      this.$emit('onSubmit', e)
    },
    remove() {
      this.isDelete = true
    },
    closeDeleteModal() {
      this.isDelete = false
    },
    async download() {
      this.loading = true
      const response = await this.downloadByReportId({
        reportId: this.reportId
      })

      this.loading = false

      const downloadLink = this.$refs.downloadLink
      downloadLink.href = response.s3Url
      downloadLink.click()
    },
    showNotify(message, color, icon) {
      const { timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    removed() {
      if (!this.isReport) {
        this.removedForModel()
      } else {
        this.removedForReport()
      }
    },
    checkName() {
      this.name = this.name.trimStart()
    },
    async removedForModel() {
      await this.removeModel({ modelId: this.modelId })

      if (this.getErrorRemovingModel.length) {
        const { color, icon } = this.$q.config.alert
        this.showNotify(this.getErrorRemovingModel, color, icon)
        return
      }

      const { colorEmpty, iconEmpty } = this.$q.config.alert
      this.showNotify(
        `Le modèle a été supprimé`,
        colorEmpty,
        iconEmpty
      )
      this.$router.push('/templates')
    },
    async removedForReport() {
      await this.removeReport({ reportId: this.reportId })

      if (this.getErrorRemovingReport.length) {
        const { color, icon } = this.$q.config.alert
        this.showNotify(this.getErrorRemovingReport, color, icon)
        return
      }

      const { colorEmpty, iconEmpty } = this.$q.config.alert
      this.showNotify('Le document a été supprimé', colorEmpty, iconEmpty)
      this.$router.push('/documents')
    }
  },
  watch: {
    getErrorDownload: function(message) {
      if (message && message.length) {
        const { color, icon } = this.$q.config.alert
        this.showNotify(message, color, icon)
      }
    },
    getName: function(status) {
      if (status) this.onSubmit()
    },
    name: function(newName) {
      if (this.defaultName !== newName) {
        setTimeout(() => {
          this.$emit('nameFileChanged', true)
        }, 500)
      } else {
        this.$emit('nameFileChanged', false)
      }
    }
  }
}
</script>
