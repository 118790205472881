<template>
  <div :class="['q-page-left document', { 'disabled-blur': getPlacedStatus }]">
    <AddEntityTemplate
      :is-show="showModalAddEntity"
      @closeModal="closeModalAddEntity"
      @middle-open-panel="openMiddlePanel"
      :content="getEntityList"
    />
    <SaveEntityModal
      :is-show="showModalSaveEntity"
      :content="getNameForSaveModal"
      @closeModal="closeModalSaveEntity"
      @discardChanges="cancelEntity"
      @saved="saveEntityLocal"
    />
    <DeleteModalTemplate
      :is-show="showModalDeleteEntity"
      :content="getNameForSaveModal"
      @closeModal="closeModalDeleteEntity"
      @removed="_deleteEntity"
    />
    <UnsavedChanges
      :isShow="getShowUnsavedChanges.status"
      :content="content"
      @closeModal="closeModalUnsavedChanges"
      @discardChanges="cancelUnsavedChanges"
      @saved="saveUnsavedChanges"
    />
    <div v-if="getPlacedStatus" class="blur-placer__uniq" v-bind:style="blurColStyles"></div>
    <div
      :class="!getPlacedStatus ? 'actions-side' : 'actions-side disabled-blur'"
      ref='actionsside'
    >
      <template v-if="!showListEnity">
        <q-btn
          class="btn-new"
          icon-right="add"
          label="Ajouter une entité"
          @click="openModalAddEntity"
        />
      </template>
      <template v-else>
        <q-btn
          class="btn-cancel"
          :disable="getErrorEntityName"
          label="Annuler"
          @click="openModalSaveEntity"
        />
        <q-btn
          class="btn-save"
          :disable="getErrorEntityName"
          label="Sauver"
          @click="saveEntityLocal()"
        >
          <q-icon class="save-icon" />
        </q-btn>
        <q-btn
          v-if="getEdit && !getTypeShort"
          label="Supprimer"
          class="btn-trash"
          @click="openModalDeleteEntity"
        >
          <q-icon class="trash-icon" />
        </q-btn>
      </template>
    </div>
    <div class="entity-list" ref="entitylist">
      <div
        class="item entity-item"
        v-for="(item, index) in getEntityList"
        :key="index"
      >
        <div
          :class="[
            'item',
            'entity-item',
            'item-header',
            { blur: getPlacedStatus }
          ]"
        >
          <img
            @click="toggleAccordion(item)"
            :class="[
              'accordion',
              { 'accordion-close': accordions.includes(item.id) }
            ]"
            src="../../assets/icons/icon-arrow-down.svg"
            alt=""
          />
          <span @click="toggleAccordion(item)">
            {{ item.name || item.entityLabel }}
          </span>
          <button
            v-if="item.status === 'read-only'"
            class="edit-btn__custom"
            @click="_editEntity(index)"
          >
            <img src="../../assets/icons/edit.svg" alt="" />
          </button>
        </div>
        <div :class="[{ hidden: accordions.includes(item.id) }]">
          <div class="empty empty-entity" v-if="!item.fields.length">
            <img src="../../assets/icons/empty-entity.svg" />
            <div>Aucune information ajoutée</div>
            <p>
              Sélectionnez les informations que vous souhaitez utiliser dans ce modèle depuis la liste d’informations à droite.
            </p>
          </div>
          <template v-if="item.fields.length && item.fields[0].template">
            <div
              :class="
                getTypeShort && item.status === 'read-only'
                  ? 'item-body item-body-blur'
                  : 'item-body'
              "
            >
              <div
                v-for="(field, index_field) in item.fields"
                :key="index_field"
                :data-field="
                  !getPlacedStatus && !field.placedStatus
                    ? ''
                    : 'Cliquer sur le document pour définir l’emplacement de cette information'
                "
                :class="[
                  'item-field',
                  {
                    blur: field.placedStatus !== true,
                    'item-field__focused': field.editable
                  }
                ]"
              >
                <q-tooltip
                  v-if="
                    !getPlacedStatus &&
                      isShowTooltip(`${item.name} - ${field.text}`)
                  "
                >
                  {{ item.name }} - {{ field.text }}</q-tooltip
                >
                <div class="item-field-content">
                  <span class="item-field-content__title">
                    {{ item.name }} - {{ field.text }}
                  </span>
                  <span
                    class="item-field-content__sub-title"
                    v-if="!field.isPlaced"
                  >
                    Pas d'emplacement
                  </span>
                  <span
                    class="item-field-content__sub-placed"
                    v-if="field.pages.length"
                  >
                    Emplacement: p. {{ field.pages | join }}
                  </span>
                  <span
                    class="item-field-content__sub-placed"
                    v-if="field.positions.length"
                  >
                    Emplacement: p. {{ field.positions | join }}
                  </span>
                </div>
                <div v-if="field.placedStatus & !isLastField" class="note">
                  Cliquer sur le document pour <br>définir l’emplacement de <br> cette information
                </div>
                <button
                  @click="removeFromSelected(index_field)"
                  class="item-field-action"
                  v-if="item.status === 'edit'"
                >
                  <i
                    aria-hidden="true"
                    role="presentation"
                    class="material-icons q-icon notranslate"
                    >close</i
                  >
                </button>
                <q-btn
                  v-else
                  label="Placer"
                  v-show="!getPlacedStatus"
                  @click="selectPlacer(index, index_field, item, field)"
                ></q-btn>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="item-body">
              <div
                :class="
                  !field.editable
                    ? 'item-field'
                    : 'item-field item-field__focused'
                "
                v-for="(field, index_field) in item.fields"
                :key="index_field"
              >
                <q-tooltip
                  v-if="!field.editable && isShowTooltip(`${field.dataLabel}`)"
                >
                  {{ field.dataLabel }}</q-tooltip
                >
                <div class="item-field-content item-field-content__custom">
                  <span
                    v-if="field.dataConfidence === 'LOW'"
                    class="low"
                  ></span>
                  <span
                    v-else-if="field.dataConfidence === 'HIGH'"
                    class="high"
                  ></span>
                  <span v-else class="manual">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="9" cy="9" r="9" fill="#FA9917" />
                      <path
                        d="M9 5V9"
                        stroke="#333333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9 13H9.01"
                        stroke="#333333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span
                    class="item-field-content__title item-field-content__title--combine"
                  >
                    {{ field.dataLabel }}
                    <br />
                    <span v-if="!field.editable">
                      {{ field.dataValue }}
                    </span>
                  </span>
                </div>
                <div class="input-hidden" v-if="field.editable">
                  <textarea
                    v-model="editPlaceholderValue"
                    class="editor-placeholder"
                  />
                  <label class="input-hidden__actions">
                    <q-btn
                      class="btn-cancel"
                      label="Annuler"
                      @click="editEtityPlaceholder(item, field, false)"
                    />
                    <q-btn
                      class="btn-save"
                      label="Save changes"
                      @click="saveEditedEntityPlaceholder(item, field, false)"
                    >
                      <q-icon class="save-icon" />
                    </q-btn>
                  </label>
                </div>
                <button
                  @click="removeFromSelected(index_field)"
                  class="item-field-action"
                  v-if="item.status === 'edit'"
                >
                  <i
                    aria-hidden="true"
                    role="presentation"
                    class="material-icons q-icon notranslate"
                    >close</i
                  >
                </button>
                <template v-else>
                  <img
                    v-if="!field.editable"
                    class="edit-btn__custom"
                    @click="editEtityPlaceholder(item, field, true)"
                    src="../../assets/icons/edit-blue.svg"
                    alt=""
                  />
                  <q-btn
                    v-if="!field.editable"
                    label="place"
                    @click="selectEntityElement(item, field)"
                  ></q-btn>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-if="getPlacedStatus" class="placed-actions" :style="{ left: buttonsLeftPosition, bottom: buttonBottomPosition }">
      <q-btn class="btn-cancel" label="Annuler" @click="resetPlaced" />
      <q-btn
        v-if="getDocFocused"
        class="btn-new"
        label="Confirmer"
        @click="confirmSelectEntityElement"
      />
    </div>
    <div class="empty" v-if="!getEntityList.length">
      <img src="../../assets/icons/add-entities.svg" />
      <div>Pas d'entité créée</div>
      <p>
        Ajoutez une entité (partie au contrat) en cliquant sur “Ajouter une entité” et sélectionnez l’ensemble des informations que vous souhaitez voir apparaitre dans le modèle.
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AddEntityTemplate from '@/components/modals/AddEntityTemplate'
import SaveEntityModal from '@/components/modals/SaveEntityTemplate'
import DeleteModalTemplate from '@/components/modals/DeleteEntitytTemplate'
import UnsavedChanges from '@/components/modals/UnsavedChanges'
import isEqual from 'lodash.isequal'
import Vue from 'vue'

export default {
  name: 'LeftPanel',
  components: {
    AddEntityTemplate,
    SaveEntityModal,
    DeleteModalTemplate,
    UnsavedChanges
  },
  props: {
    place: {
      type: Object,
      default: () => {}
    },
    modelId: {
      type: String,
      default: ''
    },
    reportId: {
      type: String,
      default: ''
    },
    file: {
      type: Object,
      default: () => {}
    },
    isOpenMiddlePanel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      buttonsLeftPosition: "215px",
      buttonBottomPosition: "",
      isLastField: false,
      blurColStyles: { },
      showListEnity: false,
      addEntityState: false,
      saveEntityModal: false,
      deleteEntityModal: false,
      editPlaceholderValue: '',
      dataPlaced: [],
      selectEntity: {},
      accordions: [],
      content: {
        title: 'Sauvegarder les changements',
        description: 'Sauvergarder les changements apportés à ce modèle avant de quitter la page ?'
      }
    }
  },
  async created() {
    this.setEntityList([])
    this.fetchListPlaceholders()
    if (this.getTypeShort && this.getReportId) {
      await this.$store
        .dispatch('templates/fetchProjectId', this.getReportId)
        .then(val => {
          this.setReportDataResponse(val)
          this.setFetchEntityList(val)
          this.setReportName(val.reportName)
          this.fetchReplaceEntities(val.projectId)
        })
    }

    if (this.getModelId !== '') {
      await this.fetchListEntity(this.getModelId)
    }

    const getEntityList = [...this.getEntityList]
    this.setEntityListDublicate(getEntityList)
  },
  computed: {
    ...mapGetters('templates', [
      'getShowNewFields',
      'getEdit',
      'getEntityList',
      'getSingleEntity',
      'getDocumentType',
      'newReportData',
      'getReportDataResponse',
      'getReportName',
      'getEntityId',
      'getOldLabel',
      'getEntityListDublicate',
      'getPlacedStatus',
      'getDocFocused',
      'getErrorEntityName',
      'getShowUnsavedChanges'
    ]),
    getSelectEntity() {
      return this.selectEntity
    },
    getModelId() {
      return this.modelId
    },
    showModalAddEntity() {
      return this.addEntityState
    },
    showModalSaveEntity() {
      return this.saveEntityModal
    },
    showModalDeleteEntity() {
      return this.deleteEntityModal
    },
    getNameForSaveModal() {
      return this.getSingleEntity.name
    },
    getTypeShort() {
      return this.getDocumentType === 'document'
    },
    getReportId() {
      return this.reportId
    },
    getPlacedData() {
      return this.dataPlaced
    },
    getPutListEntity() {
      const newArr = {
        reportName: this.getReportName,
        reportData: []
      }
      for (const entity of this.getEntityList) {
        const editedEntity = {}
        editedEntity.entityCode = !entity.type
          ? entity.code
          : this.getOldLabel.toUpperCase()
        editedEntity.entityLabel = !entity.type
          ? entity.label
          : this.getOldLabel
        editedEntity.entityValue = entity.name
        editedEntity.entityId = entity.id || this.getEntityId
        editedEntity.entityData = []

        for (const prop of entity.fields) {
          const newObj = {}
          newObj.dataCode = prop.dataCode || prop.code
          newObj.dataLabel = prop.dataLabel || prop.label
          newObj.dataValue = prop.dataValue || prop.value
          newObj.dataConfidence = prop.dataConfidence || 'MANUAL'
          newObj.dataYear = prop.dataYear
          newObj.dataId = prop.dataId
          newObj.locked = !!prop.locked
          editedEntity.entityData.push(newObj)
        }
        newArr.reportData.push(editedEntity)
      }
      return newArr
    }
  },
  methods: {
    ...mapActions('templates', [
      'setEntityList',
      'setS3Url',
      'setPlacedStatus',
      'setPlacedStatusForField',
      'setFocusedDocument',
      'setReportDataResponse',
      'setFetchEntityList',
      'setReportName',
      'fetchReplaceEntities',
      'fetchListEntity',
      'clearSingleEntity',
      'clearStateEdit',
      'deleteEntity',
      'fetchListPlaceholders',
      'removeSelected',
      'setEntityOldList',
      'setOldLabel',
      'editEntity',
      'setEditableStateForPlaceholder',
      'saveEntity',
      'setShowNewFields',
      'fetchUpgradeEntity',
      'fetchMailMerge',
      'setEditableDataForPlaceholder',
      'setEntityListDublicate',
      'setShowUnsavedChanges'
    ]),
    matchBlurHeight() {
      var heightString = this.$refs.entitylist.clientHeight +  this.$refs.actionsside.clientHeight + 'px';
      Vue.set(this.blurColStyles, 'height', heightString); 
    },
    isShowTooltip(title) {
      if (title.length > 43) {
        return true
      }
      return false
    },
    confirmSelectEntityElement() {
      this.selectEntityElement(
        this.getSelectEntity.item,
        this.getSelectEntity.field
      )
      this.resetPlaced()
    },
    resetPlaced() {
      if (this.getPlacedData && this.getPlacedData.length) {
        this.setPlacedStatusForField([
          this.getPlacedData[0],
          this.getPlacedData[1],
          false
        ])
      }
      this.setPlacedStatus(false)
      this.setFocusedDocument(false)
      this.getSelectEntity.item = {}
      this.getSelectEntity.field = {}
    },
    saveEditedEntityPlaceholder(el, field) {
      const indexEntity = this.getEntityList.indexOf(el)
      const indexPlaceholder = this.getEntityList[indexEntity].fields.indexOf(
        field
      )
      const val = this.editPlaceholderValue
      this.setEditableDataForPlaceholder([indexEntity, indexPlaceholder, val])
      this.editPlaceholderValue = null
      this.fetchUpgradeEntity([this.getReportId, this.getPutListEntity]).then(
        () => {
          this.fetchMailMerge(this.getReportId).then(i => {
            this.setS3Url({
              type: this.file.typeShort,
              s3Url: i.s3Url,
              name: this.file.name
            })
          })
        }
      )
    },
    editEtityPlaceholder(el, field, flag) {
      const indexEntity = this.getEntityList.indexOf(el)
      const indexPlaceholder = this.getEntityList[indexEntity].fields.indexOf(
        field
      )
      if (flag) {
        this.editPlaceholderValue = field.dataValue
      } else {
        this.editPlaceholderValue = null
      }
      this.setEditableStateForPlaceholder({
        indexEntity,
        indexPlaceholder,
        flag
      })
    },
    async cancelEntity() {
      this.setEntityOldList(this.getEntityListDublicate)
      this.showListEnity = false
      this.$emit('openMiddlePanel')
      this.clearSingleEntity()
      this.closeModalSaveEntity()
    },
    _editEntity(index) {
      if (!this.getEdit) {
        this.showListEnity = true
        this.$emit('openMiddlePanel')
        this.editEntity(index)
        this.setOldLabel(
          this.getEntityList[index].dataLabel || this.getEntityList[index].name
        )
      }
    },
    closeModalDeleteEntity() {
      this.deleteEntityModal = false
    },
    openModalDeleteEntity() {
      this.deleteEntityModal = true
    },
    closeModalSaveEntity() {
      this.saveEntityModal = false
    },
    openModalSaveEntity() {
      if (this.getEntityListDublicate && this.getEntityListDublicate.length) {
        const _isEqual = isEqual(
          this.getEntityList,
          this.getEntityListDublicate
        )
        if (!_isEqual) {
          this.saveEntityModal = true
        } else {
          this.setEntityOldList(this.getEntityListDublicate)
          this.showListEnity = false
          this.$emit('openMiddlePanel')
          this.clearSingleEntity()
          this.closeModalSaveEntity()
        }
      } else {
        this.saveEntityModal = true
      }
    },
    closeModalAddEntity() {
      this.addEntityState = false
    },
    openModalAddEntity() {
      this.addEntityState = true
    },
    openMiddlePanel() {
      this.showListEnity = true
      this.$emit('openMiddlePanel')
      this.closeModalAddEntity()
    },
    _deleteEntity() {
      this.closeModalDeleteEntity()
      this.setShowUnsavedChanges({ status: null, payload: {} })
      this.$emit('openMiddlePanel')
      this.showListEnity = false
      this.deleteEntity()
      this.saveEntity()
    },
    saveEntityLocal() {
      if (
        this.getTypeShort &&
        this.getSingleEntity.fields.length //&&
        // !this.getSingleEntity.fields[0].template
      ) {
        const reportData = this.getPutListEntity.reportData.map(report => {
          const entityData = report.entityData.map(entity => {
            const newEntity = {
              dataCode: entity.dataCode,
              dataId: entity.dataId,
              dataLabel: entity.dataLabel,
              dataValue: entity.dataValue,
              dataConfidence: entity.dataConfidence || 'MANUAL'
            }
            if (entity.dataValue !== null) {
              // true
              newEntity.locked = false
            } else {
              newEntity.dataValue = ''
              newEntity.dataId = ''
              newEntity.locked = false
            }
            return newEntity
          })

          return {
            ...report,
            entityData
          }
        })

        this.fetchUpgradeEntity([
          this.getReportId,
          {
            reportName: this.getPutListEntity.reportName,
            reportData
          }
        ]).then(() => {
          this.fetchMailMerge(this.getReportId).then(i => {
            this.setS3Url({
              type: this.file.typeShort,
              s3Url: i.s3Url,
              name: this.file.name
            })
          })
        })
      }
      this.saveEntity(!this.showNewFields)

      this.setShowNewFields(false)

      this.$emit('openMiddlePanel')

      this.showListEnity = false

      this.closeModalSaveEntity()
    },
    removeFromSelected(index) {
      this.removeSelected(index)
      if (this.getShowNewFields) {
        this.fetchUpgradeEntity([this.getReportId, this.getPutListEntity])
      }
    },
    selectEntityElement(entity, element) {
      this.$emit('entityElementForDocument', { entity, element })
    },
    selectPlacer(index, indexField, item, field) {
      // Yes, I know. I was tired.
      var total_fields_number = 0
      var entity_i = 0
      var index_selected = 0
      var field_i = 0
      var total_i = 0
      this.getEntityList.map(entity_data => {
          field_i = 0
          total_fields_number += entity_data.fields.length
          for (var field_elem in entity_data.fields) {
            if (field_i == indexField & entity_i == index) {
              index_selected = total_i
            } 
            field_i += 1
            total_i += 1
          }
          entity_i += 1
      })

      if (total_fields_number > 10 & total_fields_number - index_selected < 3) {
        this.isLastField = true
        if (total_fields_number - index_selected < 2) {
          this.buttonBottomPosition = "75px"
        } else {
          this.buttonBottomPosition = "20px"
        }
      } else {
        this.isLastField = false
        this.buttonBottomPosition = "20px"
      }


      this.selectEntity.item = item
      this.selectEntity.field = field
      this.dataPlaced = [index, indexField]
      this.setPlacedStatus(true)
      this.setPlacedStatusForField([index, indexField, true])
      this.matchBlurHeight()
    },
    toggleAccordion(item) {
      const accordionIndex = this.accordions.findIndex(
        accordion => accordion === item.id
      )
      if (accordionIndex !== -1) {
        const accordions = this.accordions.filter(
          accordion => accordion !== item.id
        )
        this.accordions = accordions
      } else {
        this.accordions.push(item.id)
      }
    },
    saveUnsavedChanges() {
      this.$emit('saveUnsavedChanges')
    },
    cancelUnsavedChanges() {
      this.setEntityOldList(this.getEntityListDublicate)
      this.showListEnity = false
      this.clearSingleEntity()
      const payload = this.getShowUnsavedChanges.payload
      this.setShowUnsavedChanges({ status: false, payload: {} })
      payload.next()
    },
    closeModalUnsavedChanges() {
      this.setShowUnsavedChanges({ status: false, payload: {} })
    }
  },
  watch: {
    place: function(data) {
      if (Object.keys(data).length) {
        const result = this.getEntityList.map(list => {
          const index = list.fields?.findIndex(field => {
            return (
              field.code === data.data.element.code &&
              list.id === data.data.entity.id &&
              field.dataYear === data.data.element.dataYear
            )
          })

          if (data.page && index !== -1) {
            list.fields[index] = {
              ...list.fields[index],
              isPlaced: true,
              dataCode: list.fields[index].dataCode,
              pages: [...list.fields[index].pages, data.page]
            }
          }

          if (data.position && index !== -1) {
            list.fields[index] = {
              ...list.fields[index],
              isPlaced: true,
              dataCode: list.fields[index].dataCode,
              pages: [...list.fields[index].pages, data.position]
            }
          }

          return list
        })

        this.setEntityList(result)
      }
    },
    getDocFocused: function(status) {
      if (status == true) {
        this.buttonsLeftPosition = "165px"
      } else {
        this.buttonsLeftPosition = "215px"
      }
    }
  },
  filters: {
    join(arr) {
      return arr.join(', ')
    }
  },
  beforeDestroy() {
    this.setShowUnsavedChanges({ status: false, payload: {} })
    this.resetPlaced()
  },
  destroyed() {
    this.clearStateEdit()
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 600px) {
  .q-dialog .q-card {
    max-width: 418px;
  }
}
</style>
