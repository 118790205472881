<template>
  <div class="q-page-middle">
    <div class="q-page-middle--item">
      <p class="q-page-middle--item__title mt-30">
        {{ getEdit ? 'Modifier l’entité' : 'Nouvelle entité' }}
      </p>
      <div
        v-if="getTypeShort && getEdit"
        class="q-page-middle--item q-page-middle--item__select"
      >
        <div class="form-container form-container__height-auto">
          <q-form class="template-name fd-column">
            <div class="template-name--select__title">
              {{ entityData.name }} replace with
            </div>
            <q-select
              v-model="documentEntityName"
              :options="getDocumentEntities.entities"
              option-label="entityName"
              option-value="entityName"
              @input="setNewEntityData($event)"
            />
          </q-form>
        </div>
      </div>
      <div v-else class="form-container">
        <q-form class="template-name" @submit.stop.prevent>
          <!-- This input is disabled because changing the name of an entity -->
          <!-- breaks the template placeholder - To patch it !  -->
          <!-- The entityCode should never change - Only the label -->
          <!-- Or find and replace or mailmerge replace by placeholder -->
          <q-input
            :disable=getEdit
            counter
            :maxlength="100"
            square
            outlined
            bottom-slots
            type="text"
            v-model="entityData.name"
            debounce="500"
            filled
            :rules="[
              val => (val && val.length > 0) || 'Veuillez entrer le nom de l\'entité',
              val => validateEntityName(val) || 'Ce nom existe déjà'
            ]"
          >
            <template v-slot:hint>
              Nom de l'entité
            </template>
          </q-input>
        </q-form>
      </div>
    </div>
    <div class="q-page-middle--item q-page-middle--item__select">
      <p class="q-page-middle--item__title">
        Liste des informations
      </p>
      <div class="form-container form-container__height-auto">
        <q-form class="template-name fd-column">
          <div class="template-name--select__title">
            Catégorie d'informations
          </div>
          <q-select
            v-model="entityData.placeholder"
            :options="getListPlaceholders"
            option-label="categoryName"
            option-value="categoryName"
            @input="_setSingleEntityPlaceholder($event)"
            class="custom-select"
          >
          </q-select>
        </q-form>
      </div>
    </div>
    <div class="header header__disabled">
      <q-form @submit.stop.prevent>
        <q-input
          v-model="entityData.search"
          debounce="500"
          filled
          placeholder="Rechercher une information"
          class="search search-placeholders"
          ref="search"
        >
          <template v-slot:before>
            <img src="../../assets/icons/filter-search.svg" alt="" />
          </template>
          <template v-slot:append>
            <q-icon name="close" @click="clearSearch" />
          </template>
        </q-input>
      </q-form>
    </div>
    <div class="selects-fields">
      <div v-if="!getShowNewFields">
        <div v-for="(field, index) in setFieldsForCategory" :key="index">
          <template v-if="field.isYearable === false">
            <q-checkbox
              :key="index"
              type="checkbox"
              :value="entityData.fields"
              :val="field.value"
              @click.native="selectChexbox(field.value)"
            >
              <template v-slot>
                <div class="">
                  <div class="item-field-content item-field-content__custom">
                    <div
                      class="item-field-content__title item-field-content__title--combine"
                    >
                      {{ field.label }}
                    </div>
                    <q-tooltip v-if="isShowTooltip(field.label)">
                      {{ field.label }}</q-tooltip
                    >
                  </div>
                </div>
              </template>
            </q-checkbox>
          </template>
          <div
            class="revenues-block"
            v-if="
              !getShowNewFields &&
                field.isYearable &&
                field.possibleYears.length > 0
            "
            @click="openRevenue(field.value)"
          >
            <span
              :class="{
                'icon-vector': true,
                'icon-vector__open': openRevenueCode === field.value
              }"
            >
              <template v-if="openRevenueCode !== field.value">
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 0.999999"
                    stroke="#424552"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </template>
              <template v-else-if="openRevenueCode === field.value">
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L13 1"
                    stroke="#424552"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </template>
            </span>
            <span
              class="item-field-content__title item-field-content__title--combine"
              >{{ field.label }}</span
            >
            <q-tooltip v-if="isShowTooltip(field.label)">
              {{ field.label }}</q-tooltip
            >
          </div>
          <div class="revenue-options">
            <q-checkbox
              :key="`${field.value}_${possible.value}`"
              v-for="possible in field.possibleYears"
              v-show="
                !getShowNewFields &&
                  field.isYearable &&
                  field.possibleYears &&
                  field.possibleYears.length > 0 &&
                  openRevenueCode === field.value
              "
              type="checkbox"
              :value="entityData.fields"
              :val="`${field.value}__DY__${possible.possibleYearCode}`"
              @click.native="
                selectChexbox(
                  `${field.value}__DY__${possible.possibleYearCode}`
                )
              "
            >
              <template v-slot>
                <div>
                  <div class="item-field-content item-field-content__custom">
                    <div
                      class="item-field-content__title item-field-content__title--combine"
                    >
                      {{ possible.label }}
                    </div>
                  </div>
                </div>
              </template>
            </q-checkbox>
          </div>
        </div>
      </div>
      <q-checkbox
        v-else
        v-for="(field, index) in getNewEntityFields"
        :key="index"
        v-model="entityData.fields"
        :val="field.dataCode"
        @input="checkItem($event, true)"
      >
        <template v-slot>
          <div class="item-field">
            <div class="item-field-content item-field-content__custom">
              <span
                v-if="field.dataValues[0].dataConfidence === 'LOW'"
                class="low"
              ></span>
              <span
                v-else-if="field.dataValues[0].dataConfidence === 'HIGH'"
                class="high"
              ></span>
              <span v-else class="manual">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="9" cy="9" r="9" fill="#FA9917" />
                  <path
                    d="M9 5V9"
                    stroke="#333333"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 13H9.01"
                    stroke="#333333"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div
                class="item-field-content__title item-field-content__title--combine"
              >
                {{ field.dataLabel }}
                <br />
                <span>
                  {{ field.dataValues[0].dataValue }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </q-checkbox>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MiddlePanel',
  data() {
    return {
      documentEntityName: null,
      isOpenRevenues: false,
      openRevenueCode: null,
      firstLoad: true,
      entityData: {
        name: null,
        id: null,
        placeholder: { categoryName: 'All' },
        fields: [],
        possibleYears: [],
        status: null,
        listFields: [],
        search: null,
        searchOfCategories: false
      }
    }
  },
  created() {
    this.setEntity()
    this.setEntityListDublicate(this.getEntityList)
    this.firstLoad = false
  },
  computed: {
    ...mapGetters('templates', [
      'getEntityList',
      'getShowNewFields',
      'getNewEntityFields',
      'getSingleEntity',
      'getEdit',
      'getListPlaceholders',
      'getDocumentEntities',
      'getReportDataResponse',
      'getIndexEditingEntity',
      'getDocumentType'
    ]),
    searchReactive() {
      return this.entityData.search
    },
    getSingleEntity() {
      return JSON.parse(
        JSON.stringify(this.$store.getters['templates/getSingleEntity'])
      )
    },
    getListPlaceholders() {
      return JSON.parse(
        JSON.stringify(this.$store.getters['templates/getListPlaceholders'])
      )
    },
    setFieldsForCategory() {
      const sortingRes = []

      if (this.entityData.placeholder) {
        let res = this.getListPlaceholders.filter(
          i => i.categoryName === this.entityData.placeholder.categoryName //&&
          // i.isYearable === false
        )

        for (const category of res[0].categoryLabels) {
          const newObj = {
            ...category
          }
          newObj.label = category.dataLabel
          newObj.value = category.dataCode
          newObj.isYearable = category.isYearable
          if (category.possibleYears) {
            newObj.possibleYears = category.possibleYears.map(possibleYears => {
              return {
                ...possibleYears,
                label: possibleYears.possibleYearLabel,
                value: possibleYears.possibleYearCode
              }
            })
          }

          sortingRes.push(newObj)
        }
      }

      if (this.searchReactive !== null) {
        const newSortingRes = sortingRes.filter(i =>
          i.label.toLowerCase().includes(this.searchReactive.toLowerCase())
        )

        return newSortingRes
      } else {
        return sortingRes
      }
    },
    getTypeShort() {
      return this.getDocumentType === 'document'
    }
  },
  methods: {
    ...mapActions('templates', [
      'setEntityListDublicate',
      'setIdInEntityItem',
      'setEntityId',
      'setShowNewFields',
      'setReportData',
      'clearOldFields',
      'clearSingleEntityFields',
      'setFieldsEntity',
      'setShowNewFields',
      'setSingleEntityPlaceholder',
      'setSingleEntityName',
      'setErrorEntityName'
    ]),
    setEntity() {
      this.entityData.name = this.getSingleEntity.name
      if (this.getEdit) {
        const a = this.getListPlaceholders.filter(i =>
          i.categoryLabels.some(
            a => a.dataCode === this.getSingleEntity.fields[0]?.code
          )
        )
        if (a.length) {
          this.entityData.placeholder = a[0]
        } else {
          return
          const category = newVal.filter(
            category => category.categoryName === 'All'
          )[0]
          this.entityData.placeholder.categoryLabels = category.categoryLabels
        }
        this.entityData.placeholder = a[0]
        const newFields = []
        for (const prop of this.getSingleEntity.fields) {
          if (prop.dataYear === '0') {
            newFields.push(prop.value)
          } else {
            const code = prop.code || prop.dataCode
            const value = `${code}__DY__${prop.dataYear}`
            newFields.push(value)
          }
        }
        this.entityData.fields = newFields
      } else {
        this.entityData.name = this.getSingleEntity.name
      }
    },
    validateEntityName(name) {
      const _name = name.trim()
      const findEntityName = this.getEntityList.filter((entity, index) => {
        if (index !== this.getEntityList.length - 1) {
          return entity.name === _name
        }
      })
      if (findEntityName.length) {
        this.setErrorEntityName(true)
        return false
      }
      this.setErrorEntityName(false)
      return true
    },
    setNewEntityData(e) {
      this.setIdInEntityItem([this.getIndexEditingEntity, e.entityId])
      this.setEntityId(e.entityId)
      this.setShowNewFields(true)
      this.setReportData(this.getSingleEntity)
      this.setSingleEntityName(e.entityName)

      this.$store.dispatch('templates/fetchFilterDataEntity', [
        e.entityId,
        this.getReportDataResponse[this.getIndexEditingEntity]
      ])
      this.clearOldFields()
      this.clearSingleEntityFields()
      this.entityData.fields = []
    },
    _setSingleEntityPlaceholder(e) {
      this.setSingleEntityPlaceholder(e.categoryName)
    },
    checkItem(e, documentArgument = false, revenue = false, parent = false) {
      const newArr = []
      if (!documentArgument) {
        for (const selected of e) {
          const findParent = this.entityData.placeholder.categoryLabels.find(
            category => category.dataCode === parent
          )
          if (parent) {
            for (const prop in findParent.possibleYears) {
              if (
                selected ===
                `${findParent.dataCode}__${findParent.possibleYears[prop].possibleYearCode}`
              ) {
                const newObj = {}
                newObj.text =
                  findParent.dataLabel +
                  '-' +
                  findParent.possibleYears[prop].possibleYearLabel
                newObj.placedStatus = false
                newObj.isPlaced = false
                newObj.pages = []
                newObj.positions = []
                newObj.template = true
                newObj.isYearable = findParent.isYearable
                newObj.code = findParent.dataCode
                newObj.dataYear = selected
                newObj.value = selected
                newArr.push(newObj)
              }
            }
          } else {
            const find = this.entityData.placeholder.categoryLabels.find(
              category => {
                if (category.isYearable && category.possibleYears.length) {
                  return category.possibleYears.find(possible => {
                    return (
                      `${category.dataCode}__${possible.possibleYearCode}` ===
                      selected
                    )
                  })
                }
                return (
                  category.isYearable &&
                  `${category.dataCode}__${category.dataYear}` === selected
                )
              }
            )
          }

          for (const prop in this.entityData.placeholder.categoryLabels) {
            if (
              selected ===
              this.entityData.placeholder.categoryLabels[prop].dataCode
            ) {
              const newObj = {}
              newObj.text = this.entityData.placeholder.categoryLabels[
                prop
              ].dataLabel
              newObj.value = selected
              newObj.placedStatus = false
              newObj.isPlaced = false
              newObj.pages = []
              newObj.positions = []
              newObj.template = true
              newObj.isYearable = this.entityData.placeholder.categoryLabels[
                prop
              ].isYearable
              newObj.code = this.entityData.placeholder.categoryLabels[
                prop
              ].dataCode
              newArr.push(newObj)
            }
          }
        }
        this.setFieldsEntity([newArr, documentArgument])
      } else {
        for (const selected of e) {
          for (const prop of this.getNewEntityFields) {
            if (selected === prop.dataCode) {
              const newObj = {}
              newObj.dataCode = prop.dataCode
              newObj.editable = false
              newObj.locked = false
              newObj.template = false
              newObj.dataLabel = prop.dataLabel
              newObj.dataId = prop.dataValues[0].dataId
              newObj.dataValue = prop.dataValues[0].dataValue
              newObj.dataConfidence = prop.dataValues[0].dataConfidence
              newObj.dataYear = 0
              newArr.push(newObj)
            }
          }
        }
        this.setFieldsEntity([newArr, documentArgument])
      }
    },
    clearSearch() {
      this.entityData.search = ''
      this.$refs.search.blur()
    },
    openRevenue(code) {
      this.openRevenueCode = this.openRevenueCode === code ? null : code
    },
    selectChexbox(value) {
      const isIncluded = this.entityData.fields.includes(value)
      if (isIncluded === false) {
        const fields = [...this.entityData.fields]
        fields.push(value)
        this.$set(this.entityData, 'fields', fields)
      }
    },
    isShowTooltip(title) {
      if (title.length > 50) {
        return true
      }
      return false
    }
  },
  watch: {
    'entityData.name': function(newValue, oldValue) {
      if (this.getEdit) {
        return
      }
      const name = newValue.trimStart()
      if (this.validateEntityName(name)) {
        this.$set(this.entityData, 'name', name)
        this.setSingleEntityName(name)
      }
    },
    'entityData.fields': function(newValues, oldValues) {
      if (oldValues.length == 0 && this.firstLoad == true) {
        this.firstLoad = false
        return
      }

      const findNewValue = newValues.find(x => !oldValues.includes(x))
      const findOldValue = oldValues.find(x => !newValues.includes(x))
      // debugger
      if (findNewValue) {
        const newArr = [...this.getSingleEntity.fields]
        const codes = findNewValue.split('__DY__')
        if (codes.length === 1) {
          for (const prop in this.entityData.placeholder.categoryLabels) {
            if (
              findNewValue ===
              this.entityData.placeholder.categoryLabels[prop].dataCode
            ) {
              const newObj = {}
              newObj.text = this.entityData.placeholder.categoryLabels[
                prop
              ].dataLabel
              newObj.value = findNewValue
              newObj.placedStatus = false
              newObj.isPlaced = false
              newObj.pages = []
              newObj.positions = []
              newObj.template = true
              newObj.isYearable = this.entityData.placeholder.categoryLabels[
                prop
              ].isYearable
              newObj.dataCode = this.entityData.placeholder.categoryLabels[
                prop
              ].dataCode
              newObj.code = this.entityData.placeholder.categoryLabels[
                prop
              ].dataCode
              newObj.dataYear = '0'
              newArr.push(newObj)
            }
          }
        }
        if (codes.length === 2) {
          const categoryLabels = this.entityData.placeholder.categoryLabels.find(
            el => el.dataCode === codes[0]
          )
          const possible = categoryLabels.possibleYears.find(
            possible => possible.possibleYearCode === codes[1]
          )

          const newObj = {}
          newObj.text = `${categoryLabels.dataLabel} - ${possible.possibleYearLabel}`
          newObj.value = findNewValue
          newObj.placedStatus = false
          newObj.isPlaced = false
          newObj.pages = []
          newObj.positions = []
          newObj.template = true
          newObj.isYearable = categoryLabels.isYearable
          newObj.dataCode = categoryLabels.dataCode
          newObj.dataYear = possible.possibleYearCode || '0'
          newArr.push(newObj)
        }

        this.setFieldsEntity([newArr, false])
      }
      // if (findOldValue) {
      //   let newArr = this.getSingleEntity.fields
      //   newArr = newArr.filter(el => el.value !== findOldValue)
      //   this.setFieldsEntity([newArr, false])
      // }
    },
    getNewEntityFields: function(getNewEntityFields) {
      const { name } = this.$route
      const isEditDocument = ['EditDocument'].includes(name)
      if (isEditDocument) {
        const newArr = []
        for (const prop of getNewEntityFields) {
          const newObj = {
            editable: false,
            locked: false,
            template: false,
            dataCode: prop.dataCode,
            dataLabel: prop.dataLabel,
            dataId: prop.dataValues[0]?.dataId,
            dataValue: prop.dataValues[0]?.dataValue,
            dataConfidence: prop.dataValues[0]?.dataConfidence,
            value: false
          }
          newArr.push(newObj)
        }
        this.setFieldsEntity([newArr, true])
      }
    },
    // getSingleEntity: {
    //   handler: function(val) {
    //     if (val.fields.length !== this.entityData.fields.length) {
    //       const newFields = []
    //       for (const prop of val.fields) {
    //         newFields.push(prop.value || prop.dataCode)
    //       }
    //       this.entityData.fields = newFields
    //     }
    //   },
    //   deep: true
    // },
    getListPlaceholders: {
      immediate: true,
      handler: function(newVal, oldVal) {
        if (newVal && newVal.length && oldVal === undefined) {
          const category = newVal.filter(
            category => category.categoryName === 'All'
          )[0]
          this.entityData.placeholder.categoryLabels = category.categoryLabels
        }
      }
    }
  },
  destroyed() {
    this.setShowNewFields(false)
  }
}
</script>
